<template>
    <v-sheet>
      Point:{{ this.profile.points }}
      <v-row class="justify-center"><RewardsCard/><RewardsCard/></v-row>
      <v-row class="justify-center"><RewardsCard/><RewardsCard/></v-row>
      <v-row class="justify-center"><RewardsCard/><RewardsCard/></v-row>
      <v-row class="justify-center"><RewardsCard_TwoBeer :profile="this.profile"/><RewardsCard_TwoBeer/></v-row>
      
  </v-sheet>
</template>

<script>
import RewardsCard from '@/components/RewardsCard.vue';
import { auth } from '../firebaseConfig'
import RewardsCard_TwoBeer from '@/components/RewardsCard_TwoBeer.vue';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { app } from '../firebaseConfig.js'

const  db = getFirestore(app)

export default {
  name: 'RewardsView',
  components: {
    RewardsCard,
    RewardsCard_TwoBeer
  },
  beforeMount: function(){
    auth.onAuthStateChanged(user =>{
        if (user){
            this.profile.email = user.email
            this.CheckPoint(this.profile.email)
        }
        else
          this.$router.push('/')
    })

    },
  data(){
    return {
      profile:{
        email: "",
        points: 0
      }
    }
  },

  methods: {
    async CheckPoint(email){
      const userRef = doc(db, "member_list", email)
      const userSnap = await getDoc(userRef)
      if (userSnap.exists()){
        if(userSnap.data().points){
          this.profile.points = userSnap.data().points
        }
      }
    }
  }
}
</script>
