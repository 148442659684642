<template>
    <div align="center" justify="center">

        <v-card class="myclass pa-6 ma-6" color="black" variant="outlined" elevation="8" max-width="800" rounded="lg">


        <v-file-input  class="pa-6" outline :hide-details=!isPic  v-model=this.profile.picture accept="image/jpg, image/jpeg, image/tiff, image/png, image/heif" prepend-icon="mdi-camera" show-size label="Profile picture"></v-file-input>
        <v-file-input  class="pa-6" :hide-details=isIC v-model=this.profile.icFront accept="image/jpg, image/jpeg, image/tiff, image/png, image/heif" prepend-icon="mdi-camera" show-size label="IC front"></v-file-input>


       <v-btn :disabled="isDisable" block rounded class="myclass mb-8" color="black" size="large" variant="outlined" @click="upload">
            Update profile
        </v-btn>
        </v-card>
     </div>
</template>

<script>
// import {ref} from 'vue'
import { auth } from '../firebaseConfig'
import { getStorage, ref as firebaseRef, uploadBytes } from 'firebase/storage';
import { app } from '../firebaseConfig.js'
import { getFirestore, doc ,setDoc, collection } from 'firebase/firestore';
const db = getFirestore(app)

export default{
    name: "AccountFormUploadImage",
    components:{
        
    },
    data() {
        return {
            rules: [
                files => !files || files.size < 4194304 || 'File cannot be more than 4 MB!'
            ],
            profile: {
                picture: '',
                icFront: ''
            }
        }
    },
    computed:{
        isDisable(){
            return !(this.profile.picture) || !(this.profile.icFront)
        },
        isPic(){
            return !(this.profile.picture)
        }
    },
    created: function(){
        auth.onAuthStateChanged(user =>{
            if (user){
                this.profile.email = user.email
            }
        })

    },
    methods:{
        
        async upload(){
            const storage = getStorage();
            const profilePicRef = firebaseRef(storage, this.profile.email.toString()+"/profile.jpg")
            const profileICRef = firebaseRef(storage, this.profile.email.toString()+"/IC-front.jpg")
            const userRef = collection(db,"verify_list")
            await uploadBytes(profilePicRef, this.profile.picture).then((snapshotPic) =>{
                console.log(snapshotPic)
                    uploadBytes(profileICRef, this.profile.icFront).then((snapshot) =>{
                    console.log(snapshot)
                })
            })
            await setDoc(doc(userRef, this.profile.email),{
                isVerified: false
            })


            this.$router.push('/account')
        }
    }
}
</script>