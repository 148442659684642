<template>
    <div align="center" justify="center">
        <v-date-picker class="rounded-xl" border show-adjacent-months title="Select Date to check" color="black" elevation="1" v-model="selectedDate" :max="maxDate" :min="minDate" ></v-date-picker>
        <div class="pa-4">
        <v-btn class="myclass mx-6" variant="outlined" rounded @click="CheckAvability" :disabled="isVerified">Check avability</v-btn>
        </div>
        <v-col class="mx-auto">
        <v-btn class="myclass ma-4" variant="outlined" rounded @click="SelectDayShift" :disabled="isMorningDisable" v-if="isVisible"> 10am-5pm </v-btn>
        <v-btn class="myclass ma-4" variant="outlined" rounded @click="SelectNightShift" :disabled="isNightDisable" v-if="isVisible"> 6pm-2am </v-btn>
        </v-col>
        <div class="pa-4 mx-6" >
            <v-btn class="myclass" variant="outlined" rounded @click="ApplyShift" v-if="isApplyBtnVisible"> Apply </v-btn>
        </div>
    
        <v-card-text v-if="isFull">No more slot for this day</v-card-text>
        <v-card-text v-if="isApplied">You have applied job on this day {{appliedShift}}</v-card-text>

        <v-dialog  max-width="500" v-model="isActive">
            <v-card class="border-xl border-surface">
                <v-card-text align="center">
                    You have applied job for {{ comfimationText }}
                </v-card-text>
                <v-spacer/>
                <v-btn
                    rounded
                    class="myclass ma-6"
                    text="Close"
                    color="black"
                    variant="outlined"
                    @click="isActive = false"
                    ></v-btn>
            </v-card>
        </v-dialog>

        <v-dialog max-width="500" v-model="isProfileIncomplete" >
            <v-card class="border-xl border-surface" >
                <v-card-text v-if="!isUpdated" align="center">
                    Please update your profile 
                </v-card-text>
                <v-card-text v-if="isUpdated" align="center">
                    Please wait for verification 
                </v-card-text>

                <v-btn 
                    rounded
                    class="myclass ma-6"
                    color="black"
                    variant="outlined"
                    text="Close"
                    @click="isProfileIncomplete = false"
                    ></v-btn>
                
            </v-card>
        </v-dialog>

        
    </div>
</template>

<script>
// import { useDate }from 'vuetify'
import {ref} from 'vue'
import { getFirestore, doc, collection, getDoc, setDoc} from 'firebase/firestore';
import { app } from '../firebaseConfig.js'
import { auth } from '../firebaseConfig'


const date = new Date()
date.setDate(date.getDate() + 7);
const smallDate = new Date().toISOString().slice(0,10)
const bigDateISO = date.toISOString().slice(0,10)
const db = getFirestore(app)

export default{
    name: 'DatePicker',
    beforeCreate(){
    // to check user if login on firebase, no the route to login
    auth.onAuthStateChanged(user => {
            this.email = user.email
            this.verification(this.email)
            });
    },
    data() {
        return {
            isApplied: false,
            appliedShift: ref(String),
            isMorningDisable: false,
            isNightDisable: false,
            isVisible: false,
            isActive: false,
            isProfileIncomplete: false,
            isVerified: false,
            isApplyBtnVisible: false,
            isDayShift: false,
            isNightFull: false,
            isDayFull: false,
            isUpdated: false,
            date: ref(date),
            selectedDate: ref(date),
            dateOffset: ref(date),
            maxDate: bigDateISO,
            minDate: smallDate,
            email: '',
            comfimationText: '',
            username: '',
        }
    },
    watch: {
        selectedDate: function(){
            this.reset()
        }

    },
    methods: {
        async verification(){
        const userRef = doc(db, "member_list", this.email)
        const userSnap = await getDoc(userRef)
            if (userSnap.exists()){
                this.isProfileIncomplete = !userSnap.data().isVerified
                this.isVerified = !userSnap.data().isVerified
                this.isUpdated = userSnap.data().isUpdated
                console.log("Verified status: " + this.isProfileIncomplete)
            }
        },
        async ApplyShift() {
            //adding offset because I am lazy to fix US and MY date
            const dateToApply = this.selectedDate.toLocaleString('en-GB',{timeZone: 'Asia/Kuala_Lumpur'}).slice(0,10).replaceAll('/','-')
            const dateRef = collection(db,"booking_date")

            try {
                await setDoc(doc(dateRef,dateToApply),{
                    [this.username]: [this.isDayShift ? "Day" : "Night" , false]
                    
            })
                this.isMorningDisable = false
                this.isNightDisable = false
                this.isVisible = false
                this.reset()
                this.comfimationText = (this.isDayShift ? "Day Shift on " : "Night Shift on " ) +  dateToApply
                this.isActive = true
            } catch (error) {
                console.log(error)
            }
   

                
            
        },


        async CheckAvability(){
            this.reset()
            const userRef = doc(db, "member_list", this.email)
            const userSnap = await getDoc(userRef)
            if (userSnap.exists()){
                if (userSnap.data().username)
                    this.username = userSnap.data().username

                    const dateToCheck = this.selectedDate.toLocaleString('en-GB',{timeZone: 'Asia/Kuala_Lumpur'}).slice(0,10).replaceAll('/','-')
                    const dateRef = doc(db,"booking_date",dateToCheck)
                    const blockRef = doc(db,"block_booking_date", dateToCheck)
                    this.isVisible = true
                    this.isDisable = false

                    const blockSnap = await getDoc(blockRef)
                    if (blockSnap.exists()){
                        if (blockSnap.data().isNightShiftBlocked)
                            this.isNightDisable = true
                        if (blockSnap.data().isDayShiftBlocked)
                            this.isMorningDisable = true
                    }
                    else{
                        const docSnap = await getDoc(dateRef)
                        console.log("Here")
                        if (docSnap.exists()) {
                            console.log(docSnap.data())
                            if(docSnap.data()[this.username]){
                                this.isApplied = true
                                this.isNightDisable = true
                                this.isMorningDisable = true
                                if(docSnap.data()[this.username][0]=="Day")
                                    this.appliedShift = "10AM-5PM"
                                else if(docSnap.data()[this.username][0]=="Night")
                                    this.appliedShift = "6PM-2AM"
                                console.log("You have applied for today") 
                            }
                        }
                    }
                }   
            else {
                // add error dialog
                console.log("Update profile first")
                return 0
            }
            
            

        },

        reset(){
            this.isMorningDisable= false
            this.isNightDisable= false
            this.isVisible= false
            this.isActive= false
            this.isApplyBtnVisible = false
            this.isApplied = false
        },

        SelectDayShift(){
            this.isDayShift = true
            this.isApplyBtnVisible = true
        },

        SelectNightShift(){
            this.isDayShift = false
            this.isApplyBtnVisible = true
        },
    }

}


</script>