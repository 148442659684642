<template>
    <v-sheet>
    <!-- <img alt="Pub logo" src="../assets/placeholder_logo.png"> -->
        <AccountPage />
    </v-sheet>
</template>
  
<script>
import AccountPage from '@/components/AccountPage.vue';
import { useStore} from "vuex";
import { useRouter } from "vue-router";
import { ref, computed } from "vue";
import { auth } from '../firebaseConfig'


export default {
    name: 'AccountView',
    components: {
        AccountPage,
    },
    data(){
        return {
            profile: ref(null)
        }
    },
    beforeCreate(){
    // to check user if login on firebase, no the route to login
    auth.onAuthStateChanged(user => {
            if (user){
                // userId = user.uid
                this.profile = user.email
            }
                // store.dispatch("fetchUser", user);
            else 
                this.$router.push('/')
            
            });
    },
    setup() {
        // var userId = ""
        const store = useStore()
        const router = useRouter()
      
        const user = computed(() => {
            return store.getters.user;
        });

        const signOut = async () => {
            await store.dispatch('logOut')
            router.push('/')
    }

        return {user, signOut}
    },
}

</script>
  