<template>
    <v-card 
    class="pa-6 ma-6"
    color=#FFFFFF
    image="https://i.pinimg.com/originals/16/1d/fe/161dfecea9be36170e8b0c448c6e7c5e.jpg"
    min-width="300"
    max-width="800"
    title= "One beer"
    @click.stop="dialog = true"
    >
    <!-- <template v-slot:actions >
        <v-btn
        append-icon="mdi-chevron-right"
        color="red-lighten-2"
        text="Claim Now"
        variant="outlined"
        block
        ></v-btn>
    </template> -->
    </v-card>


    <v-dialog v-model="dialog" scrollable max-width="80%">
      <v-card>
        <v-card-title>Confirmation</v-card-title>
        <v-divider></v-divider>
        <v-card-text height="200px">Redeem one beer with 100pts</v-card-text>
        <v-btn
            text="Confirm"
            @click="dialog = false"
            ></v-btn>
        <v-btn
            text="Cancel"
            @click="dialog = false"
            ></v-btn>
      </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: 'RewardsCard',
    data(){
        return{
            dialog: false
        }
    }
}
</script>