<template>
    <v-sheet>
    <div class ="home">
        <h1>{{ msg }}</h1>
        <v-row class="justify-center"><DayMenuCard/></v-row>
        <v-row class="justify-center"><NightMenuCard/></v-row>
        <v-row class="justify-center"><ViewCard/></v-row>
        <v-row class="justify-center"><AboutUs/></v-row>
        <!-- <img style="max-width: 100rem" src="https://t4.ftcdn.net/jpg/05/59/58/33/360_F_559583384_CtRfePjNUpSMOMH348InWmhCB7GGOH9S.jpg"> -->
    </div>
  </v-sheet>
</template>

<script>
import ViewCard from './ViewCard.vue';
import DayMenuCard from './DayMenuCard.vue';
import NightMenuCard from './NightMenuCard.vue';
import AboutUs from './AboutUsCard.vue';

export default {
  name: 'HomePage',
  components:{
    ViewCard,
    DayMenuCard,
    NightMenuCard,
    AboutUs
},
  props: {
    msg: String
  }
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
