import { createApp } from 'vue'
import App from './App.vue'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import router from './router'
import * as labsComponents from 'vuetify/labs/components'
import '@mdi/font/css/materialdesignicons.css'
import store from './store'
import Vuex from "vuex";
// import { VueFire, VueFireAuth } from 'vuefire'
// import { firebaseApp } from './firebase'

const vuetify = createVuetify({
    components,
    directives,
    labsComponents,
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi,
      },
    },
  })


const app = createApp(App).use(router).use(vuetify).use(Vuex).use(store)

// app.use(VueFire, {
//   // imported above but could also just be created here
//   firebaseApp,
//   modules: [
//     // we will see other modules later on
//     VueFireAuth(),
//   ],
// })

app.mount('#app')