<template>
    <div align="center" justify="center">
        <v-card class="myclass pa-6 ma-6" color="black" variant="outlined" elevation="8" max-width="800" rounded="lg">

            <!-- <div class="text-high-emphasis d-flex align-center justify-space-between">Email</div> -->
            <v-text-field v-model=this.profile.email disabled="true" label="Email" placeholder="email" ></v-text-field>
            
            <!-- <div class="text-high-emphasis d-flex align-center justify-space-between">Name</div> -->
            <v-text-field v-model=this.profile.username label="Name"></v-text-field>

            <!-- <div class="text-high-emphasis d-flex align-center justify-space-between">Phone Number</div> -->
            <v-text-field v-model=this.profile.phoneNumber label="Phone Number" placeholder="phone number" ></v-text-field>

            <v-select label="Gender" v-model=this.profile.gender :items="['Male','Female']"/>

            <v-date-input v-model=this.profile.birthday label="Birthday"></v-date-input>

            <v-spacer/>
            <!-- <v-file-input v-model=this.profile.picture label="Profile picture"></v-file-input> -->

            <v-btn block rounded class="myclass mb-8" color="black" size="large" variant="outlined" @click="update">
                Next Step
            </v-btn>

            <!-- <v-btn block rounded class="myclass mb-8" color="black" size="large" variant="outlined" @click="upload">
                Upload Image
            </v-btn> -->

            

        </v-card>
     </div>
</template>

<script>
import { VDateInput } from 'vuetify/labs/VDateInput'
import {ref} from 'vue'
import { auth } from '../firebaseConfig'
import { getFirestore, doc, getDoc ,updateDoc } from 'firebase/firestore';
import { app } from '../firebaseConfig.js'
import { getStorage, ref as firebaseRef, uploadBytes } from 'firebase/storage';
const db = getFirestore(app)

export default{
    name: "AccountForm",
    components:{
        VDateInput
    },
    data() {
        return {
            rules: [
                files => !files || files.size < 4194304 || 'File cannot be more than 4 MB!'
            ],
            profile: {
                username: "",
                email: "",
                phoneNumber: "",
                gender: "",
                birthday: ref(null),
                picture: ref(Image)
            }
        }
    },
    created: function(){
        auth.onAuthStateChanged(user =>{
            if (user){
                this.profile.email = user.email
                this.getUserInfo(this.profile.email)
            }
        })

    },
    methods:{
        async getUserInfo(email){
            const userRef = doc(db, "member_list", email)
            const userSnap = await getDoc(userRef)
            if (userSnap.exists()){
                console.log(userSnap.data())
                this.profile.phoneNumber = userSnap.data().phoneNumber
                this.profile.username = userSnap.data().username
                this.profile.gender = userSnap.data().gender
            }
        },
        async update(){
            const userRef = doc(db,"member_list", this.profile.email)
            console.log(userRef)
            await updateDoc(userRef,{
                gender: this.profile.gender,
                username: this.profile.username,
                phoneNumber: this.profile.phoneNumber,
                birthday: this.profile.birthday,
                isUpdated: true
            })
            this.$router.push('/update-image')
        },
        async upload(){
            const storage = getStorage();
            const profilePicRef = firebaseRef(storage, this.profile.email.toString()+"/profile.jpg")
            console.log(profilePicRef.name)
            uploadBytes(profilePicRef, this.profile.picture).then((snapshot) =>{
                console.log(snapshot)
                console.log("Uploaded")
            })
            this.$router.push('/account')
        }
    }
}
</script>