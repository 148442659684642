<template>
    <v-sheet min-height="2200">
        <div ></div>
        <MenuBar/>
    </v-sheet>
</template>

<script>
import MenuBar from '@/components/MenuBar.vue';

export default {
  name: 'MenuBarView',
  components: {
    MenuBar,
  },
}
</script>