export default{
        "1000": "Email has been registered",
        "1001": "Profile failed to save",
        "2000": "Account is logged in at other device",
        "2001": "Login failed",
        "2002": "Incorrect password or username",
        "2003": "Email is not yet registered",
        "2004": "Account has been temporarily disabled due to many failed login attempts",
        "3000": "Insuffient point to redeem",
        "3001": "Unable to find reward item",
        "3003": "Transaction failed",
        "4000": "Failed to book the time slot",
        "4001": "This time slot is full",
        "4002": "This account is not yet verified",
        "5001": "Unexpected error"
    
}
// 1*** for register
// 2*** for login
// 3*** for rewards
// 4*** for booking
// 5*** for unknown error