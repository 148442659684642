<template>
    <div class="menu" align="center" justify="center">
        <h1>Pizza</h1>
        <v-card class="myclass pa-3 ma-6" 
        color="black" 
        variant="outlined" 
        elevation="8" 
        max-width="800" 
        rounded="lg"
        style="text-align: left;">
                <h3>PREMIUM 高级披萨 </h3>
                <h5>1.ITALIAN Prosciutto Di Parma PIZZA 意大利帕尔马 </h5>
                <h6>(Topped with premium Parma ham from Italy, rocket, based with Italiano Tomatoes Sauce | RM35</h6>

                <h5>2.MALA PIGGY PIZZA 麻辣小猪</h5>
                <h6>(Topped with Si-Chuan Mala stir-fry minced pork, 
                sliced yellow onion. Based with Italiano Tomatoes Sauce | RM25)  </h6>
                <br/>
                <h3>CLASSIC 经典披萨</h3>
                <h5>3.BEEF PEPPERONI PIZZA 牛肉香肠 </h5>
                <h6>(Topped with Premium Beef Pepperoni & Sliced 
                Yellow Onion. Based with Italiano Tomatoes Sauce | RM21)  </h6>
                <h5>4.BIG BREAKFAST PIZZA 大早餐 </h5>
                <h6>(Topped with roasted bacon, egg, hashbrown, button 
                mushroom. based with Italiano Tomato Sauce | RM20)  </h6>
                <h5>5.BACON & PEPPERONI PIZZA 培根与香肠 </h5>
                <h6>(Topped with roasted bacon, premium 
                chicken pepperoni, sliced yellow onion and new zealand cheese. Based with Italiano 
                Tomato Sauce | RM20)  </h6>
                <h5>6.CHICKEN PEPPERONI PIZZA 鸡肉香肠 </h5>
                <h6>(Topped with Premium Chicken Pepperoni & 
                Sliced Yellow Onion. Based with Italiano Tomatoes Sauce | RM18)</h6>  
                <h5>7.ROASTED CHICKEN PIZZA 香烤鸡肉 </h5>
                <h6>(Topped with oven-braised chicken breast, 
                lettuce based with home-made tomato sauce | RM18 [thumbs up for breakfast] )</h6>  
                <h5>8.SALAD PIZZA 青青沙拉 </h5>
                <h6>(Topped with olive, button mushroom, rocket, hashbrown. 
                Based with honey mustard sauce | RM16)  </h6>
                <h5>9.CLASSIC MARGHERITA PIZZA 玛格丽 </h5>
                <h6>(Topped with basil leave. Based with Italiano 
                Tomato Sauce | RM15) </h6>
        </v-card>
    
        <h1>Bagel</h1>
        <v-card class="myclass pa-3 ma-6" 
        color="black" 
        variant="outlined" 
        elevation="8" 
        max-width="800" 
        rounded="lg"
        style="text-align: left;">
                <h5>1.Blueberry Cream Cheese 蓝莓奶油乳酪- RM12 </h5>
                <h5>2.Genmaicha Cream Cheese 玄米茶奶油乳酪 - RM12 </h5>
                <h5>3.AyoMayo Matcha Crunchy Almond Butter  抹茶杏仁黄油- RM12  </h5>
                <h5>4.AyoMayo Almond Butter - 杏仁黄油RM11 </h5>
                <h5>5.Black Sesame Mochi 黑芝麻麻糬 -RM11 </h5>
                <h5>6.Matcha Milky 抹茶香奶  - RM11 </h5>
                <h5>7.Classic Chocolate 经典巧克力 -RM 10 </h5>
                <h5>8.Charcoal Taro Floss 竹炭芋泥肉松 -RM10 </h5>
                <h5>9.Roti - boii 咖啡巧克力- RM10  </h5>
                <h5>10.Sea Salt Cookie Brownie 海盐曲奇布朗尼  - RM8 </h5>
                <br/>
                <h4>Mix n Match Bagel any flavour - Box of 6 by RM60 </h4>
        </v-card>
    
    
        <h1>Belgium Leige Waffle</h1>
        <v-card class="myclass pa-3 ma-6" 
        color="black" 
        variant="outlined" 
        elevation="8" 
        max-width="800" 
        rounded="lg"
        style="text-align: left;">
    
                <h5>1.Bonito 柴鱼片 - RM15</h5> 
                <h6>(served with multigrain waffle) </h6>
                <h5>2.AyoMayo Almond Butter ｜ AyoMayo 杏仁黄油 - RM13 </h5>
                <h6>(serve with chocolate waffle)  </h6>
                <h5>3.AyoMayo Matcha Crunchy Almond Butter ｜ AyoMayo 抹茶杏仁 - RM13  </h5>
                <h6>(served with multigrain waffle) </h6>
                <h5>4.Chocolate Marshmallow 巧克力棉花糖 - RM12 </h5>
                <h6>(served with chocolate waffle)  </h6>
                <h5>5.Lotus -焦糖饼 RM11 </h5>
                <h6>( served with multigrain waffle)  </h6>
                <h5>6.Oreo  - 奥利奥 RM11 </h5>
                <h6>(served with chocolate waffle)  </h6>
                <br/>
                <h5>Grab n Go: Waffle 华夫饼 +  Black 黑咖啡  /  White 白咖啡 | +RM5 </h5>
        </v-card>
    
    
        <h1>Coffee</h1>
        <v-card class="myclass pa-3 ma-6" 
        color="black" 
        variant="outlined" 
        elevation="8" 
        max-width="800" 
        rounded="lg"
        style="text-align: left;">
            <h3>CLASSIC  经典</h3>
            <h5>1.Americano 美式咖啡  RM 7 </h5>
            <h5>2.Aerocano 蒸汽美式咖啡 RM8 </h5>
            <h5>3.Espresso 浓缩咖啡 RM 7 </h5>
            <h5>4.Cappuccino 卡布奇诺 RM 12 </h5>
            <h5>5.Café Latte 拿铁 RM 12 </h5>
            <h5>6.Latte Macchiato 玛奇朵 RM16 </h5>
            <h5>7.Hot Chocolate  热巧克力 RM 13 </h5>
            <h5>8.Mocha 摩卡 RM 15 </h5>
            
            
            <h3>PREMIUM 特调</h3>
            <h5>9.Soju Morning 烧酒咖啡  RM 18 </h5>
            <h5>10.Tropical Orange & Black  香橙咖啡 RM 17 </h5>
            <h5>11.Icy Honey Black 蜂蜜咖啡 RM 18 </h5>
        </v-card>

        
    </div>
</template>

<style>
.menu {
    font-family: 'Courier New', Courier, monospace;
    font-weight: lighter;
}
</style>