<template>
    <v-sheet min-height="1000">
    <!-- <Calendar/> -->
      <DatePicker></DatePicker>
    </v-sheet>
</template>

<script>
// import Calendar from '@/components/Calendar.vue';
import DatePicker from '@/components/DatePicker.vue';

export default {
  name: 'BookingView' ,
  components: {
    // Calendar,
    DatePicker,
  },

}
</script>