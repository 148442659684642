<template>
    <v-card 
    class="pa-6 ma-6"
    color=#FFFFFF
    image="https://i.pinimg.com/originals/16/1d/fe/161dfecea9be36170e8b0c448c6e7c5e.jpg"
    min-width="300"
    max-width="800"
    title= "Two beer"
    @click.stop="dialog = true"
    >
    <!-- <template v-slot:actions >
        <v-btn
        append-icon="mdi-chevron-right"
        color="red-lighten-2"
        text="Claim Now"
        variant="outlined"
        block
        ></v-btn>
    </template> -->
    </v-card>


    <v-dialog v-model="dialog" scrollable max-width="100%" min-height="15%">
      <v-card>
        <v-card-title>Confirmation</v-card-title>
        <v-divider></v-divider>
        <form @submit.prevent="redeem">
            <v-card-text height="200px">Please enter your table number</v-card-text>
            <v-text-field 
            name="tableNumber"
            label="Table Number"
            id="tableNumber"
            v-model="tableNumber" 
            :rules="tableRules"
            required 
            class="px-10" 
            type="number"/>
            <v-spacer></v-spacer>
            <v-row class="d-flex justify-space-between">
                <v-btn 
                    class="mx-8 "
                    text="Cancel"
                    @click="dialog = false"
                    ></v-btn>
                <v-btn
                    type="submit"
                    color= green
                    class="mx-8"
                    text="Confirm"
                    ></v-btn>
            </v-row>
            <v-spacer></v-spacer>
        </form>
        
      </v-card>
    </v-dialog>
</template>

<script>
import { getFirestore, doc , updateDoc } from 'firebase/firestore';
import { app } from '../firebaseConfig.js'

const db = getFirestore(app)

export default {
    name: 'TwoBeer',
    props: {
        profile: Object,
    },
    data(){
        return{
            dialog: false,
            tableNumber: null,
            tableRules: [v => !v || /^[1-9][0-9]?$|^20$/.test(v) || 'Table number is not valid' ]
        }
    },
    methods:{
        async redeem(){
            if(this.profile.points< 100000000){
                console.log("not enought point")
            }
            else{
                const userRef = doc(db, "member_list", this.profile.email)
                await updateDoc(userRef,{
                    "points": this.profile.points -190
                })
                this.dialog = false
                this.$router.go()
            }
            
        }
    }
}
</script>
