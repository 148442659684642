import { createStore } from 'vuex'
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { getFirestore, doc, collection, getDoc, setDoc} from 'firebase/firestore';
import { app } from './firebaseConfig.js'

const auth = getAuth();
const db = getFirestore(app);
const memberRef = collection(db, "member_list")

const store = createStore({
    state() {
        return {
            user: {
                loggedIn: false,
                data: null,
                username: ''
            },
            error: {
                isError: false,
                message: "Unexpected error"
            }
        }
        
    },
    getters: {
        user(state){
            return state.user
        },
        error(state){
            return state.error
        }
    },
    mutations: {
        SET_LOGGED_IN(state, value) {
          state.user.loggedIn = value;
        },
        SET_USER(state, data) {
          state.user.data = data;
        },
        ERROR(state, value) {
            state.error.isError = true,
            state.error.message = value
        }
    },
    actions: {
        async register(context, { email, password, username, phoneNumber}){
            var response;
            try{
                response = await createUserWithEmailAndPassword(auth, email, password)
            }catch(error){
                return 5001
            }
            if (response) {
                context.commit('SET_USER', response.user)
                //add to firestore
                try {
                    await setDoc(doc(memberRef, email),{
                        username: username,
                        phoneNumber: phoneNumber,
                        uid: response.user.uid
                    })
                    return 200
                }catch (error){
                    console.log(error)
                    return 1001
                }
            } else {
                return 1000
            }
        },
  
        async login(context, { email, password }){
            var response
            try {
                response = await signInWithEmailAndPassword(auth, email, password)
            }
            catch(err){
                console.log(err)
                return err
            }

            if (response) 
                console.log("Wrong at here")
                console.log(response)
                return response
        },
  
        async logOut(context){
            await signOut(auth)
            context.commit('SET_USER', null)
        },
    
        async fetchUser(context ,user) {
            context.commit("SET_LOGGED_IN", user !== null);
            if (user) {
            context.commit("SET_USER", {
                displayName: user.displayName,
                email: user.email
            });
            } else {
            context.commit("SET_USER", null);
            }
        },
        async addUser(email, username, phoneNumber, uid){
            try {
                await setDoc(doc(memberRef, email),{
                    username: username,
                    phoneNumber: phoneNumber,
                    uid: uid
                })
            }catch (error){
                throw new Error ("unable to create user in db")
            } finally{
                console.log("User done")
            }
        },

        async getUser(email){
            try {
                const userRef = doc(db,"member_list",email)
                const userSnap = await getDoc(userRef)
                if (userSnap.exists){
                    return userSnap
                }
                else{
                    throw new Error ("User not found in db")
                }
            }catch (error){
                throw new Error("Unable to retrieve user in db")
            } finally{
                console.log("Done retrieve user data")
            }
        }
    }
})

// export the store
export default store