<template>
    <v-img :src="partOne"></v-img>
    <v-img :src="partTwo"></v-img>
    <v-img :src="partThree"></v-img>
    <v-img :src="partFour"></v-img>
    <v-img :src="partFive"></v-img>

    
</template>

<script>
export default {
  name: 'MenuBar',
  data(){
    return{
        partOne: require('../assets/nightmenu-part1.png'),
        partTwo: require('../assets/nightmenu-part2.png'),
        partThree: require('../assets/nightmenu-part3.png'),
        partFour: require('../assets/nightmenu-part4.png'),
        partFive: require('../assets/nightmenu-part5.png')
    }
  }
}
</script>