import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import Login from '../views/LoginPage.vue'
// import Register from '@/views/RegisterPage.vue'
import SignUp from '@/views/SignUp.vue'
import SignIn from '@/views/SignIn.vue'
import RewardsView from '@/views/RewardsView.vue'
import Booking from '@/views/Booking.vue'
import AccountView from '@/views/AccountView.vue'
import AccountForm from '@/components/AccountForm.vue'
import AccountFormUploadImage from '@/components/AccountFormUploadPicture.vue'
import MenuCafe from '@/views/MenuCafeView.vue'
import MenuBar from '@/views/MenuBarView.vue'
import AboutView from '@/views/AboutView.vue'

const routes = [
  {
    path: '/',
    name: 'ByondWalls',
    component: HomeView
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: SignIn
  },
  {
    path: '/register',
    name: 'Register',
    component: SignUp
  },
  {
    path: '/rewards',
    name: 'Rewards',
    component: RewardsView
  },
  {
    path: '/timetable',
    name: 'Timetable',
    component: Booking
  },
  {
    path: '/account',
    name: 'Account',
    component: AccountView
  },
  {
    path: '/update-info',
    name: 'Update Basic Info',
    component: AccountForm
  },
  {
    path: '/update-image',
    name: 'Update Image',
    component: AccountFormUploadImage
  },
  {
    path: '/cafe-menu',
    name: 'Cafe Menu',
    component: MenuCafe
  },
  {
    path: '/bar-menu',
    name: 'Bar Menu',
    component: MenuBar
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.name;
  next();
});

export default router
