<template>
    <div align="center" justify="center">
    <v-img aspect-ratio=1 max-width=300 
        lazy-src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
        :src=this.profile.picture></v-img>

     <v-card class="myclass pa-6 ma-6" color="black" variant="outlined" elevation="8" max-width="800" rounded="lg">
        
        <div class="text-high-emphasis d-flex align-center justify-space-between">Name</div>
        <v-text-field class="text-high-emphasis" v-model=this.profile.username disabled=true ></v-text-field>

       <div class="text-high-emphasis d-flex align-center justify-space-between">Phone Number</div>
        <v-text-field v-model=this.profile.phoneNumber disabled=true placeholder="phone number" ></v-text-field>

        <div class="text-high-emphasis d-flex align-center justify-space-between">Email</div>
        <v-text-field v-model=this.profile.email disabled=true placeholder="email" ></v-text-field>

        <div class="text-high-emphasis d-flex align-center justify-space-between">Birthday</div>
        <v-text-field v-model=this.profile.birthday disabled=true placeholder="Birthday" ></v-text-field>

        <v-btn v-if="!profile.isVerified" block rounded class="myclass mb-8" color="black" size="large" variant="outlined" @click="updateProfile" >
            Update profile
        </v-btn>

        <v-btn block class="myclass mb-8" color="black" rounded variant="outlined" size="large"  @click="signOut">
            SignOut
        </v-btn>

     </v-card>
   </div>

</template>

<script>
import { auth } from '../firebaseConfig'
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { app } from '../firebaseConfig.js'
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
const db = getFirestore(app)

export default{
    name: "AccountPage",

    data() {
        return {
            profile: {
                username: "",
                email: "",
                phoneNumber: "",
                picture: "",
                birthday: '',
                isVerified: false
            }
        }
    },
    beforeMount: function(){
        auth.onAuthStateChanged(user =>{
            if (user){
                this.profile.email = user.email
                this.getUserInfo(this.profile.email)
            }
        })

    },
    methods:{
        async getUserInfo(email){
            // var emailStr = new String (email)
            const userRef = doc(db, "member_list", email)
            const userSnap = await getDoc(userRef)
            if (userSnap.exists()){
                console.log(userSnap.data())
                this.profile.phoneNumber = userSnap.data().phoneNumber
                this.profile.username = userSnap.data().username
                this.profile.isVerified = userSnap.data().isVerified
                if(userSnap.data().birthday)
                    this.profile.birthday = new Date(userSnap.data().birthday.seconds*1000).toLocaleDateString('en-GB')
                else
                    this.profile.birthday = ''
                // this.profile.birthday =  new Date(userSnap.data().birthday.seconds*1000).toLocaleDateString('en-GB') || ''
                // console.log(this.profile.birthday.seconds)
                const storage = getStorage();
                getDownloadURL(ref(storage,email+"/profile.jpg")).then((url) =>{
                    this.profile.picture = url
                    console.log(url)
                    // const img = document.getElementById(('myimg'));
                    // img.setAttribute('src', url);
                }).catch((error) =>{
                    console.log(error.code)
                    switch (error.code) {
                        case 'storage/object-not-found':
                            this.profile.picture = null
                            break;
                        case 'storage/unauthorized':
                            this.profile.picture = null
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            this.profile.picture = null
                            // User canceled the upload
                            break;
                        case 'storage/unknown':
                            this.profile.picture = null
                            // Unknown error occurred, inspect the server response
                            break;
                    }
                })
            }
        },
        async signOut() {
            this.$store.dispatch('logOut')
            this.$router.push('/')
        },
        updateProfile(){
            this.$router.push('/update-info')
        }
    }
    
    
}
</script>