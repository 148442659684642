<template>
    <v-layout class="overflow-visible" style="height: 56px;">
      <v-bottom-navigation
        v-model="value"
        bg-color=#ffffff
        mode="shift"
        fixed
      >
        <v-btn to="/">
            <v-icon>mdi-home-outline</v-icon>
          <span>Home</span>
        </v-btn>
  
        <!-- <v-btn to="/rewards">
          <v-icon>mdi-license</v-icon>
          <span>Rewards</span>
        </v-btn> -->
  
        <v-btn to="/timetable">
          <v-icon>mdi-calendar-month-outline</v-icon>
  
          <span>Booking</span>
        </v-btn>
  
        <v-btn to="/account">
          <v-icon>mdi-account</v-icon>
  
          <span>Account</span>
        </v-btn>
      </v-bottom-navigation>
    </v-layout>
  </template>

<script setup>
</script>

<script>
export default {
    name: 'BottomNavigation',
    data(){
        return{
            value: 0
        }
    },

    computed: {
        color () {
        switch (this.value) {
            case 0: return 'blue-grey'
            case 1: return 'teal'
            case 2: return 'brown'
            case 3: return 'indigo'
            default: return 'blue-grey'
        }
        },
    },
    components: {
        
    },
    
}
</script>

<style>
.v-bottom-navigation {
  justify-content: start;
  overflow-x: scroll;
}
</style>