<template>
    <div  align="center" justify="center">

      <div class="text-center pa-4" >
        <v-dialog
          v-model="dialog"
          width="auto"
        >
          <v-card
            class="text-pre-wrap border-xl border-surface"
            max-width="400"
            :text=this.errorText
            :title=this.errorTitle
          >
            <template v-slot:actions>
              <v-btn
                rounded
                class="myclass ms-auto"
                color=black
                variant="outlined"
                text="Ok"
                @click="dialog = false"
              ></v-btn>
            </template>
          </v-card>
        </v-dialog>
      </div>
          
            <v-card class="myclass pa-6 ma-6" color="black" variant="outlined" elevation="8" max-width="800" rounded="lg">
              <v-card-text>
                  <form @submit.prevent="onSignin">
                      
                        <v-text-field
                          name="email"
                          label="Mail"
                          id="email"
                          v-model="email"
                          type="email"
                          :rules="emailRules"
                          required></v-text-field>
                      
  
                      
                        <v-text-field
                          name="password"
                          label="Password"
                          id="password"
                          v-model="password"
                          :append-inner-icon="isVisible ? 'mdi-eye-off' : 'mdi-eye'" 
                          :type="isVisible ? 'text' : 'password'"
                          @click:append-inner="isVisible = !isVisible"
                          required></v-text-field>
                      
  
                      
                        <v-btn class="myclass" rounded color="black" variant="outlined" type="submit">Log In</v-btn>
                     
  
                  </form>
              </v-card-text>
              <v-card-text align="center">
                <router-link to="/register">
                    <a class="text-blue text-decoration-none" rel="noopener noreferrer" target="_blank"></a>
                    Sign up now
                </router-link>
              </v-card-text>

              <!-- <v-card-text align="center">
                <router-link to="/reset-password">
                    <a class="text-blue text-decoration-none" rel="noopener noreferrer" target="_blank"></a>
                    Forget password?
                </router-link>
              </v-card-text> -->
            </v-card>
         
  
      </div>
  </template>
    
  <script>
  import error_code from '@/misc/error_code';
  import { auth } from '../firebaseConfig'

  export default {
      data () {
        return {
            dialog: false,
            errorText: "Unexpected error on login",
            errorTitle: "Unable to login",
            email: '',
            password: '',
            isVisible: false,
            emailRules: [
            v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
            ],
        }
      },
      beforeCreate(){
      auth.onAuthStateChanged(user => {
              if (user)
                  this.$router.push('/')
        })
      },
      computed: {
        user () {
            return this.$store.getters.user
        }
      },
      watch: {
        user (value) {
            if (value !== null && value !== undefined) {
            this.$router.push('/')
            }
        }
      },
      methods: {
        async onSignin () {
          const response = await this.$store.dispatch('login', {email: this.email, password: this.password})
          console.log(response)
          this.dialog = true
            if (String(response).includes('invalid-credential'))
              this.errorText = error_code[2002]
            else if (String(response).includes('too-many-requests'))
              this.errorText = error_code[2004]
            else if (response.user.email)
              this.$router.push('/')
            else
              this.errorText = error_code[response] + "\n\n\n" +'Error Code: '+ response
        }
      }
  }
  </script>