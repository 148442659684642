<template>
  <v-sheet>
      <AboutUs/>
  </v-sheet>
</template>

<script>
import AboutUs from '@/components/AboutUs.vue';

export default {
name: 'AboutUsView',
components: {
  AboutUs,
},
}
</script>