<template>
  <v-sheet min-height="1000" >
  <div class="home">
    <nav >
      <v-row align="center" justify="center">
        <v-img :width="600" aspect-ratio="16/3" cover :src="bannerImage" />
      </v-row>
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
    </nav>
      <!-- <img alt="Pub logo" src="../assets/placeholder_logo.png"> -->
      <HomePage msg="Welcome"/>
  </div>
</v-sheet>
</template>

<script>
// @ is an alias to /src
import HomePage from '@/components/HomePage.vue';

export default {
  name: 'HomeView',
  components: {
    HomePage,
  },
  data(){
    return{
      bannerImage: require('../assets/temp_banner.png')
    }
  }
}
</script>
