<template>
    <v-card 
    class="pa-6 ma-6 rounded-xl"
    width="700"
    variant="outlined"
    subtitle= "Where the Spirits Never Rest"
    title= "Bar Menu"
    >
    <template v-slot:actions >
        <v-btn
        class="myclass"
        rounded
        to="/bar-menu"
        append-icon="mdi-chevron-right"
        color="black"
        text="Get Tipsy"
        variant="outlined"
        block
        ></v-btn>
    </template>
    </v-card>
</template>

<script>

export default {
    name: 'DayMenuCard'
}
</script>

<style>
  .myclass.v-btn{
      border-width:3px;
      color:black;
  }
  .v-card{
    border-width: 3px;
  }
</style>