<template>
    <v-sheet min-height="2200">
        <div ></div>
        <MenuCafe/>
    </v-sheet>
</template>

<script>
// @ is an alias to /src
import MenuCafe from '@/components/MenuCafe.vue';

export default {
  name: 'MenuCafeView',
  components: {
    MenuCafe,
  },
}
</script>